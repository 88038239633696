<template>
<section class="hero is-medium has-another-background">
  <div class="hero-body has-text-centered">
    
  <img src="../../assets/main-one.png">
  </div>
</section>


<section class="section has-water-background">


<div class="columns is-desktop is-vcentered">
  <div class="column is-half fully-center">  <img src="../../assets/shack.png"></div>
  <div class="column has-text-left">
  <h1 class="title has-text-white is-size-2">The Shady Shack!</h1>
  <p>Tis be our very first spot on the Metaverse</p><p> after the ETH Island incident*</p>
  <p class="has-text-warning is-size-4">Nuestro espacio en el metaverso, estas bienvenido!.</p>
  <p><a class="button is-large is-dark" href="https://www.cryptovoxels.com/parcels/7811"><strong>👋 Visit us!</strong></a> </p>
  </div>
  </div>





<div class="columns is-desktop is-vcentered">
  <div class="column has-text-right">
  <h1 class="title has-text-white is-size-2">pAARRRty!</h1>
  <p>The gaming platform is out!</p>
  <p> help us test it while having fun with the fam!</p>
  <p class="has-text-warning is-size-4">Una plataforma de juego para que te diviertas con amigos.</p>
  <p><a class="button is-large is-dark" href="https://play.potos.io"><strong>🎮 Have fun!</strong></a> </p>
  </div>

  <div class="column is-one-third fully-center">  <img src="../../assets/ship.png"></div>

  </div>



</section>



<div class="has-black-background columns is-desktop p-5 has-text-centered">
  <div class="column">
  <img src="../../assets/hunt.png">
  <p>Join the biggest hunt</p><p>game in web3</p>
  <p class="has-text-warning is-size-4">Busca el tesoro con Potos.</p>
  <p><a class="button is-large is-dark" href="https://story.potos.io"><strong>busca!</strong></a> </p>

  </div>

  <div class="column">
  <img src="../../assets/custom.png">
  <p>Get a custom pirate.</p>
  <p>when you mint 5</p>
  <p class="has-text-warning is-size-4">obtiene 5 piratas y te hacemos uno personalizado</p>
  <a class="button is-large is-dark" href="/mint">
            <strong>🦜Mint!</strong>
          </a>
  </div>
  <div class="column">
  <img src="../../assets/story.png">
  <p>The interactive story</p>
  <p>of how the Potos came to be</p>

  <p class="has-text-warning is-size-4">se testigo de la historia de Potos.</p>

  <p><a class="button is-large is-dark" href="https://story.potos.io"><strong>coming soon</strong></a> </p>

  </div>
</div>

            <!-- <p class="mt-3">Price 0.05Ξ</p> -->



<footer class="footer has-black-background">
  <div class="content is-medium has-text-centered">
    <p>
      <strong>Potos</strong> by Marcos & Celia. The website, artwork and content
      is licensed <a href="http://creativecommons.org/licenses/by-nc-sa/4.0/">CC BY NC SA 4.0</a>.
    </p>
  </div>
</footer>
</template>


<style scoped>
</style>